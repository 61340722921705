import { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState<Array<{ text: string; sender: 'user' | 'bot' }>>([
        { text: "Hi! How can I help you today?", sender: 'bot' }
    ]);
    const [inputText, setInputText] = useState('');

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!inputText.trim()) return;

        // Add user message
        setMessages(prev => [...prev, { text: inputText, sender: 'user' }]);
        setInputText('');

        // Simulate bot response
        setTimeout(() => {
            setMessages(prev => [...prev, {
                text: "Thanks for your message! Our team will get back to you soon.",
                sender: 'bot'
            }]);
        }, 1000);
    };

    return (
        <div className="chatbot-container">
            {isOpen && (
                <div className="chatbot-window">
                    <div className="chatbot-header">
                        <h5 className="mb-0">Atlas Support</h5>
                        <button className="close-btn" onClick={toggleChat}>
                            <FeatherIcon icon="x" />
                        </button>
                    </div>
                    <div className="chatbot-messages">
                        {messages.map((message, index) => (
                            <div key={index} className={`message ${message.sender}`}>
                                {message.sender === 'bot' && (
                                    <div className="bot-avatar">
                                        <FeatherIcon icon="help-circle" />
                                    </div>
                                )}
                                <div className="message-content">
                                    {message.text}
                                </div>
                            </div>
                        ))}
                    </div>
                    <form onSubmit={handleSubmit} className="chatbot-input">
                        <input
                            type="text"
                            placeholder="Type your message..."
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                        />
                        <button type="submit">
                            <FeatherIcon icon="send" />
                        </button>
                    </form>
                </div>
            )}
            <button className="chatbot-toggle" onClick={toggleChat}>
                {isOpen ? (
                    <FeatherIcon icon="x" />
                ) : (
                    <FeatherIcon icon="message-circle" />
                )}
            </button>
        </div>
    );
};

export default Chatbot; 