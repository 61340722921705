import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import AOS from 'aos';

// routes
import Routes from './routes/Routes';
import Chatbot from './components/chatbot/Chatbot';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';

const App = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            disable: 'mobile'
        });
    }, []);

    return (
        <>
            <Helmet>
                {/* Primary Meta Tags */}
                <title>Finance Manager - Smart Personal Finance Management</title>
                <meta name="title" content="Finance Manager - Smart Personal Finance Management" />
                <meta name="description" content="Take control of your finances with AI-powered expense tracking, budgeting, and financial insights. Free personal finance management tool with premium features." />
                <meta name="keywords" content="personal finance, expense tracking, budgeting, financial management, money management, savings goals, AI finance" />
                
                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.financemanager.site/" />
                <meta property="og:title" content="Finance Manager - Smart Personal Finance Management" />
                <meta property="og:description" content="Take control of your finances with AI-powered expense tracking, budgeting, and financial insights. Start managing your money smarter today." />
                <meta property="og:image" content="/og-image.png" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.financemanager.site/" />
                <meta property="twitter:title" content="Finance Manager - Smart Personal Finance Management" />
                <meta property="twitter:description" content="Take control of your finances with AI-powered expense tracking, budgeting, and financial insights. Start managing your money smarter today." />
                <meta property="twitter:image" content="/og-image.png" />

                {/* Additional SEO Meta Tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="robots" content="index, follow" />
                <meta name="language" content="English" />
                <meta name="revisit-after" content="7 days" />
                <meta name="author" content="Finance Manager" />

                {/* Canonical URL */}
                <link rel="canonical" href="https://www.financemanager.site/" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/logo192.png" />
                
                {/* Preconnect to important third-party origins */}
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />

                {/* Structured Data for Rich Results */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "SoftwareApplication",
                        "name": "Finance Manager",
                        "applicationCategory": "FinanceApplication",
                        "operatingSystem": "Web",
                        "offers": {
                            "@type": "Offer",
                            "price": "0",
                            "priceCurrency": "USD"
                        },
                        "description": "AI-powered personal finance management tool for expense tracking and budgeting",
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "4.8",
                            "ratingCount": "1250"
                        }
                    })}
                </script>
            </Helmet>

            <Routes />
            <Chatbot />
        </>
    );
};

export default App;
